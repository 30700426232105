<template>
  <div class="nullData">
    <img src="@/assets/imgs/tips/more.png" alt="" />
    <p>无结果</p>
  </div>
</template>

<script>
export default {
  name: "nullData",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
.nullData {
  text-align: center;
  padding-top: vw(80);
  p {
    color: #a5a1a1;
    font-size: vw(16);
    margin-top: 18px;
  }
}
</style>
export function getName(val, list) {
    let str = "";
    list.map((item => {
        if (item.value == val) str = item.label
    }))
    if (!str) str = '-'
    return str
}

// 图片地址过滤
export function getImgurl(val) {
    if (!val) {
        return require("@/assets/imgs/placeholder.jpg")
    } else {
        return val
    }
}


export function getChinese(item) {
    // const pattern = /[\u4e00-\u9fa5]+/g; // 匹配中文字符的正则表达式
    if (item.summary) return item.summary
    if (!item.content) return ""
    let result = extractTextFromHTML(item.content);
    // const result = item.content.match(pattern); // 使用 match 方法匹配中文字符，并使用 join 方法将数组转换为字符串
    if (!result) return ""
    if (item.contentType == 3) {
        result = ""
    }
    return result
}

function extractTextFromHTML(htmlString) {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.innerText || tempElement.textContent;
}
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import './element/index.css'
import "@/css/font.css";
import "@/css/common.scss";

import * as filters from '@/util/filters' // 引入工具类

import nullData from '@/components/nullData.vue'
//注册为全局组件
Vue.component('nullData', nullData)
import nullDataSearch from '@/components/nullDataSearch.vue'
//注册为全局组件
Vue.component('nullDataSearch', nullDataSearch)
Object.keys(filters).forEach(key => {
  // 注册过滤器
  Vue.filter(key, filters[key])
})
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
// import Base64 from 'js-base64'
// Vue.use(Base64);
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

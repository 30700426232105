
import Vue from "vue";
import VueRouter from "vue-router";
import { getStore } from "@/util/store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    redirect: '/columnSetup',
    // columnSetup
    children: [
      // 栏目配置 start
      {
        path: "/columnSetup",
        name: "columnSetup",
        meta: {
          title: '管理栏目',
          name: "columnSetup"
        },
        component: () => import("@/views/columnConfiguration/columnSetup/index.vue"),
      },
      {
        path: "/hot",
        name: "hot",
        meta: {
          title: '热门推荐',
          name: "hot"
        },
        component: () => import("@/views/columnConfiguration/hot/index.vue"),
      },
      {
        path: "/format",
        name: "format",
        meta: {
          title: '版式管理',
          name: "format"
        },
        component: () => import("@/views/columnConfiguration/format/index.vue"),
      },
      // 栏目配置 end
      // 内容配置start
      {
        path: "/theme",
        name: "theme",
        meta: {
          title: '管理主题',
          name: "theme"
        },
        component: () => import("@/views/contentConfiguration/theme/index.vue"),
      },
      {
        path: "/createArticle/list",
        name: "createArticleList",
        meta: {
          title: '草稿箱',
          name: "createArticleList"
        },
        component: () => import("@/views/contentConfiguration/createArticle/list.vue"),
      },
      {
        path: "/createArticle",
        name: "createArticle",
        meta: {
          title: '创作内容',
          name: "createArticleList"
        },
        component: () => import("@/views/contentConfiguration/createArticle/index.vue"),
      },
      {
        path: "/unpublishedContent",
        name: "unpublishedContent",
        meta: {
          title: '未发布',
          name: "unpublishedContent"
        },
        component: () => import("@/views/contentConfiguration/unpublishedContent/index.vue"),
      },
      {
        path: "/publishContent",
        name: "publishContent",
        meta: {
          title: '已发布',
          name: "publishContent"
        },
        component: () => import("@/views/contentConfiguration/publishContent/index.vue"),
      },
      // 内容配置 end


    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.meta.title) {
    document.title = `光华东山 | ${to.meta.title}`;
  } else {
    document.title = "光华东山";
  }
  next();
  // 判断路由跳转  如果用户两小时未操作  清楚缓存
  // const time = getStore({ name: "lastRequestTime" });
  // const newTime = parseInt(new Date().getTime() / 1000);
  // if (!time) {
  //   clearStore({ type: 1 });
  //   clearStore();
  // } else if (newTime - Number(time) > 7200) {
  //   clearStore();
  //   clearStore({ type: 1 });
  // }
  // 特殊判断不需要登录
  if (to.meta.routeWhitelist) {
    next();
    return;
  }
  const userInfo = getStore({ name: "userinfo" });

  if (to.name != "login") {
    if (userInfo && userInfo.id) {
      next();
    } else {
      next({ path: "/login" });
    }
  } else if (userInfo && userInfo.id) {
    next({ name: "home" });
  } else {
    next();
  }
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router;

<template>
  <div class="nullData">
    <img src="@/assets/imgs/common/null.png" alt="" />
    <p>暂无内容</p>
  </div>
</template>

<script>
export default {
  name: "nullData",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
.nullData {
  text-align: center;
  padding-top: 140px;
  p {
    color: #bdbbbb;
    font-size: 14px;
    margin-top: 0;
  }
}
</style>